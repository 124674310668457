import http from "@/utils/http";
// 隐藏load框, {hideLoading:true}

// 图片上传token
export const uploadWebTokenApi = (params) => http.post("/api/direct_upload/upload_web_token", params);
// ocr
export const ocrApi = (param) => http.post("/api/orc/image", param);
export const drivingOcrApi = (param) => http.post("/car/orc/driving_info", param);
export const ocrIdCardApi = (param) => http.post("/car/orc/dentity_card", param);
export const ocrDriverCardApi = (param) => http.post("/car/orc/driver_card", param);

// 上传文件
export const uploadFileApi = (file) => {
    const formData = new FormData();
    formData.append('file', file); // 'file'是你服务器端接收文件的字段名
    return http.post("/api/upload/single", formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
    });
};


// 账号登录
export const adminLoginApi = (params) => http.post("/admin/login", params);
// 账号登录
export const userLoginApi = (params) => http.post("/api/v3/user/old_login", params);
// 账号登录
export const userInfoApi = (params) => http.post("/api/v3/user/get", params);

//职务设置
export const postListApi = (params) => http.post("/manager/post/list", params);
// 发送模板消息1
export const send_template = (params) => http.post("/api/v3/template/send_template", params);
// 发送模板消息2
export const send_template_wx_admin = (params) => http.post("/api/v3/template/send_template_wx_admin", params);

//部门列表
export const departmentListApi = (params, hideLoading = false) => http.post("/manager/department/list", params, {hideLoading});
//部门新增
export const createDepartmentApi = (params) => http.post("/manager/department/store", params);
//部门编辑
export const updateDepartmentApi = (params) => http.post("/manager/department/update", params);
//部门删除
export const deleteDepartmentApi = (params) => http.post("/manager/department/delete", params);

//员工列表
export const adminListApi = (params) => http.post("/manager/admin/list", params);
//员工新增
export const createAdminApi = (params) => http.post("/manager/admin/store", params);
//员工编辑
export const updateAdminApi = (params) => http.post("/manager/admin/update", params);
//员工删除
export const deleteAdminApi = (params) => http.post("/manager/admin/delete", params);
//员工重置密码
export const resetPwdAdminApi = (params) => http.post("/manager/admin/reset_password", params);
//员工分配角色
export const setRoleAdminApi = (params) => http.post("/manager/admin/role", params);


// 其他配置

// 附加服务设置
export const configSurchangeApi = (params, hideLoading = false) => http.post("/api/v3/car/surchange_config/index", params, {hideLoading});
export const createConfigSurchangeApi = (params) => http.post("/api/v3/car/surchange_config/store", params);
export const updateConfigSurchangeApi = (params) => http.post("/api/v3/car/surchange_config/update", params);
export const deleteConfigSurchangeApi = (params) => http.post("/api/v3/car/surchange_config/delete", params);
// 通用配置：调配公司、营销平台
export const configCommonApi = (params, hideLoading = false) => http.post("/api/v3/config/common/list", params, {hideLoading});
export const addConfigCommonApi = (params) => http.post("/api/v3/config/common/store", params);
export const updateConfigCommonApi = (params) => http.post("/api/v3/config/common/update", params);
// 基础设置
export const getConfigApi = (params, hideLoading = false) => http.get("/admin/config/index", {params}, {hideLoading});
export const saveConfigApi = (params) => http.post("/admin/config/store", params);
// 随车饮料
export const configDrinkApi = (params) => http.get("/admin/system/drink/list", {params});
export const createConfigDrinkApi = (params) => http.post("/admin/system/drink/store", params);
export const updateConfigDrinkApi = (params) => http.post("/admin/system/drink/update", params);
export const deleteConfigDrinkApi = (params) => http.post("/admin/system/drink/destory", params);
// 春节折扣
export const configActiveApi = (params) => http.post("/api/v3/car/active_config/index", params);
export const createConfigActiveApi = (params) => http.post("/api/v3/car/active_config/store", params);
export const updateConfigActiveApi = (params) => http.post("/api/v3/car/active_config/update", params);
export const deleteConfigActiveApi = (params) => http.post("/api/v3/car/active_config/delete", params);
// 日租金折扣
export const configLeaseApi = (params, hideLoading = false) => http.get("/admin/system/lease/list", {params}, {hideLoading});
export const updateConfigLeaseApi = (params) => http.post("/admin/system/lease/update", params);
// 分类小时价
export const configHourPriceApi = (params, hideLoading = false) => http.get("/admin/system/hour/list", {params}, {hideLoading});
export const updateConfigHourPriceApi = (params) => http.post("/admin/system/hour/update", params);
// 杂费
export const configSundryApi = (params) => http.post("/admin/sundry/list", params);


//权限列表
export const permissionListApi = (params) => http.post("/manager/permission/list", params);
//权限新增
export const createPermissionApi = (params) => http.post("/manager/permission/store", params);
//权限编辑
export const updatePermissionApi = (params) => http.post("/manager/permission/update", params);
//权限删除
export const deletePermissionApi = (params) => http.post("/manager/permission/destory", params);
//获取用户权限
export const getUserPermission = (params) => http.post("/manager/permission/get_all_permission", params);
//更新用户权限
export const updateUserPermission = (params) => http.post("/manager/permission/update_user_permission", params);


//车辆列表
export const drivingListApi = (params) => http.post("/car/driving/list", params);
//更新车系租车日历
export const update_car_dateApi = (params) => http.post("/api/car/update_car_date", params);
// 车辆详情
export const drivingDetailApi = (params) => http.post("/car/driving/edit", params);
//车辆保存
export const createDrivingApi = (params) => http.post("/car/driving/store", params);
//车辆更新
export const updateDrivingApi = (params) => http.post("/car/driving/update", params);
// 车辆成本新增
export const storeDrivingCostApi = (params) => http.post("/api/v3/insure/cost/store", params);
// 车辆成本更新
export const updateDrivingCostApi = (params) => http.post("/api/v3/insure/cost/update", params);
// 获取不同类型车辆数量
export const carCategoryNumApi = (params) => http.post("/api/v3/order/get_car_category", params);
//车辆删除（处置车辆）
export const deleteDrivingApi = (params) => http.post("/car/driving/delete", params);
//处置车辆列表
export const drivingDeleteListApi = (params) => http.post("/car/driving/deleteList", params);
//未使用车辆列表-用于新建订单搜车用
export const getDrivingUnUsedApi = (params) => http.post("/api/v3/order/store_confirm_order/unused", params);


// 获取不同类型车系数量
export const carSeriesCategoryNumApi = (params) => http.post("/api/v3/order/get_car_series_category", params);
// 车系列表
export const carSeriesListApi = (params) => http.post("/admin/car/index", params);
// 新增车系
export const createCarSeriesApi = (params) => http.post("/admin/car/store", params);
// 更新车系
export const updateCarSeriesApi = (params) => http.post("/admin/car/update", params);
// 删除车系
export const deleteCarSeriesApi = (params) => http.post("/admin/car/destory", params);
// 排期表
export const schedulingApi = (params) => http.post("/api/v3/car/scheduling", params);
// 所有车牌号
export const all_plate_number = (params) => http.post("/api/v3/car/all_plate_number", params);


// 待维修
export const pendingRepairListApi = (params) => http.post("/car/service/list", params);
// 新增维修
export const createCarRepairApi = (params) => http.post("/car/service/store", params);
// 编辑维修
export const updateCarRepairApi = (params) => http.post("/car/service/update", params);
// 维修详情
export const detailCarRepairApi = (params) => http.post("/car/service/edit", params);
// 待保养
export const pendingMaintenanceListApi = (params) => http.post("/api/v3/order/wait_maintenance", params);
// 待大保养
export const pendingMajorMaintenanceListApi = (params) => http.post("/api/v3/order/wait_tearbox", params);
// 保养历史
export const maintenanceHistoryListApi = (params) => http.post("/car/maintenance/list", params);
// 新增保养
export const createCarMaintenanceApi = (params) => http.post("/car/maintenance/store", params);
// 保养更新
export const updateMaintenanceApi = (params) => http.post("/car/maintenance/update", params);
// 保养详情
export const editMaintenanceApi = (params) => http.post("/car/maintenance/edit", params);

// 新建保险单
export const createInsureApi = (params) => http.post("/api/v3/insure/company/store", params);
// 保险公司列表
export const companyListApi = (params) => http.post("/api/v3/insure/company/list", params);


// 访问记录
export const accessListApi = (params) => http.post("/api/visit/index", params);
// 出勤记录新增
export const attendanceStoreApi = (params) => http.post("/api/v3/order/attendance/store", params);
// 出勤记录更新
export const attendanceUpdateApi = (params) => http.post("/api/v3/order/attendance/update", params);

// 顾客列表
export const customerListApi = (params) => http.post("/car/customer/list", params);
// 顾客新增
export const customerStoreApi = (params) => http.post("/car/customer/store", params);
// 顾客详情
export const customerEditApi = (params) => http.post("/car/customer/edit", params);
// 顾客更新
export const customerUpdateApi = (params) => http.post("/car/customer/update", params);
// 顾客更新租车总金额
export const customerUpdateAmountApi = (params) => http.post("/car/customer/update_amount", params);
// 顾客更新首租信息
export const customerupdate_hireApi = (params) => http.post("/car/customer/update_hire", params);
// 顾客删除
export const customerDeleteApi = (params) => http.post("/car/customer/delete", params);
// 根据openid和phone查找顾客
export const getCustomerByOpenidApi = (params) => http.post("/api/v3/order/get_customer", params);
// 小程序用户更新信息
export const mini_userUpdateApi = (params) => http.post("/api/v3/mini_user/update", params);

// 黑名单列表
export const blacklistListApi = (params) => http.post("api/v3/user/blacklist/list", params);
// 黑名单新增
export const blacklistStoreApi = (params) => http.post("api/v3/user/blacklist/store", params);
// 黑名单详情
export const blacklistEditApi = (params) => http.post("api/v3/user/blacklist/edit", params);
// 黑名单更新
export const blacklistUpdateApi = (params) => http.post("api/v3/user/blacklist/update", params);
// 黑名单删除
export const blacklistDeleteApi = (params) => http.post("api/v3/user/blacklist/delete", params);

// 套餐列表
export const getCarserviceApi = (params, hideLoading = false) => http.get("/admin/carservice/index", {params}, {hideLoading});
// 套餐新增
export const carserviceStoreApi = (params) => http.post("/admin/carservice/store", params);
// 套餐更新
export const carserviceUpdateApi = (params) => http.post("/admin/carservice/update", params);

// 优惠券列表
export const getCouponApi = (params) => http.get("/admin/coupon/list", {params});
// 优惠券新增
export const CouponStoreApi = (params) => http.post("/admin/coupon/store", params);
// 优惠券更新
export const CouponUpdateApi = (params) => http.post("/admin/coupon/update", params);

// 评论列表
export const getCommentApi = (params) => http.get("/admin/comment/list", {params});
// 评论新增
export const CommentStoreApi = (params) => http.post("/admin/comment/store", params);
// 评论更新
export const CommentUpdateApi = (params) => http.post("/admin/comment/update", params);

// 违章列表
export const illegalsLogApi = (params) => http.post("/api/v3/order/illegals/getlog", params);
// 违章列表
export const illegalsListApi = (params) => http.post("/api/v3/order/illegals/list", params);
// 违章新增
export const illegalsStoreApi = (params) => http.post("/api/v3/order/illegals/store", params);
// 违章详情
export const illegalsEditApi = (params) => http.post("/api/v3/order/illegals/edit", params);
// 违章更新
export const illegalsUpdateApi = (params) => http.post("/api/v3/order/illegals/update", params);
// 违章删除
export const illegalsDeleteApi = (params) => http.post("/api/v3/order/illegals/delete", params);

// 订单详情
export const orderDetailApi = (params) => http.post("/api/v3/order/detail", params);
// 根据订单号更新订单信息
export const orderUpdateAllApi = (params) => http.post("/api/v3/order/update_all", params);
// 月租订单对账功能
export const orderMonthDzAllApi = (params) => http.post("/api/v3/order/order_month_dz", params);
// 创建订单入账单
export const orderEntryApi = (params) => http.post("/api/v3/order/order_entry", params);
// 新建预订单
export const orderPreparatoryApi = (params) => http.post("/api/v3/order/preparatory", params);
// 新建出车订单
export const orderPlaceApi = (params) => http.post("/api/v3/order/place", params);
// 计算订单价格
export const orderConvertApi = (params) => http.post("/api/v3/order/convert", params);
// 系统客户关联
export const orderTransCustomerApi = (params) => http.post("/api/v3/order/trans_customer", params);
// 改排车型
export const orderchange_car_seriesApi = (params) => http.post("/api/v3/order/change_car_series", params);
// 确认改排
export const orderconfirm_change_seriesApi = (params) => http.post("/api/v3/mini_order/confirm_change_series", params);
// 取消订单
export const order_cancelApi = (params) => http.post("/api/v3/order/order_cancel", params);
// 修改预付款信息
export const pre_paid_infoApi = (params) => http.post("/api/v3/order/pre_paid_info", params);
// 确认接单
export const temple_changeOrderApi = (params) => http.post("/api/v3/mini_order/temple_change", params);
// 小程序待确认订单
export const shopManagerOrderApi = (params) => http.post("/api/v3/order/shop_manager/order_form", params);
// 预订单
export const perparctor_orderApi = (params) => http.post("/api/v3/order/store_confirm_order/perparctor_order", params);
// 待送达-待取回
export const get_process_statusApi = (params) => http.post("/api/v3/order/get_process_status", params);
// 回车信息
export const wait_close_carOrderApi = (params) => http.post("/api/v3/order/store_confirm_order/wait_close_car", params);
// 回车信息数量
export const wait_close_cartotalOrderApi = (params) => http.post("/api/v3/order/store_confirm_order/wait_close_car_total", params);
// 待结算
export const collect_carOrderApi = (params) => http.post("/api/v3/order/collect_car/list", params);
// 未结清
export const is_not_settleOrderApi = (params) => http.post("/api/v3/order/picking/is_not_settle", params);
// 已结算
export const get_user_order_listApi = (params) => http.post("/api/v3/order/get_user_order_list", params);
// 改价订单
export const change_priceOrderApi = (params) => http.post("/api/v3/order/change_price/status", params);
// 出车
export const pickingConfirmOrderApi = (params) => http.post("/api/v3/order/picking/confirm", params);
// 送达
export const update_process_statusOrderApi = (params) => http.post("/api/v3/order/update_process_status", params);
// 取回
export const shop_manager_returnOrderApi = (params) => http.post("/api/v3/order/shop_manager/return", params);
// 续租
export const extend_leaseOrderApi = (params) => http.post("/api/v3/mini_order/extend_lease", params);
// 客户订单记录
export const user_order_listApi = (params) => http.post("/api/v3/order/user_order_list", params);
// 客户违章记录
export const orderillegalsApi = (params) => http.post("/api/v3/order/illegals/undeal", params);
// 保存结算信息
export const pickstatisUpdateApi = (params) => http.post("/api/v3/order/pickstatis/update", params);
// 确定结清信息
export const pickstatisFinishApi = (params) => http.post("/api/v3/order/pickstatis/finish", params);
// 小程序发起退款
export const refund_orderApi = (params) => http.post("/api/v3/order/pickfinish/refund_order", params);
// 改价确认知晓
export const confirm_change_priceApi = (params) => http.post("/api/v3/order/store_confirm_order/confirm_change_price", params);

// 外调车列表
export const transferListApi = (params) => http.post("/api/v3/transfer/index", params);
// 外调车新增
export const transferStoreApi = (params) => http.post("/api/v3/transfer/store", params);
// 外调车详情
export const transferEditApi = (params) => http.post("/api/v3/transfer/edit", params);
// 外调车更新
export const transferUpdateApi = (params) => http.post("/api/v3/transfer/update", params);
// 外调车删除
export const transferDeleteApi = (params) => http.post("/api/v3/transfer/delete", params);
// 统计金额
export const transferTotalApi = (params) => http.post("/api/v3/transfer/total", params);

// 按揭单列表
export const mortgageListApi = (params) => http.post("/api/v3/car/mortgage/list", params);
// 按揭单新增
export const mortgageStoreApi = (params) => http.post("/api/v3/car/mortgage/store", params);
// 按揭单详情
export const mortgageDetailApi = (params) => http.post("/api/v3/car/mortgage/detail", params);
// 按揭单更新
export const mortgageUpdateApi = (params) => http.post("/api/v3/car/mortgage/update", params);
// 按揭单删除
export const mortgageDeleteApi = (params) => http.post("/api/v3/car/mortgage/delete", params);
// 单个按揭还款
export const mortgageRepaymentSingleApi = (params) => http.post("/api/v3/car/mortgage/repayment_single", params);
// 单个车辆结清
export const mortgageRemainingSettlementApi = (params) => http.post("/api/v3/car/mortgage/remaining_settlement", params);
// 批量按揭还款
export const mortgageRepaymentBatchApi = (params) => http.post("/api/v3/car/mortgage/repayment_batch", params);
// 获取可以按揭车牌号
export const isAuthMortgageCarApi = (params) => http.post("/api/v3/car/mortgage/is_auth_mortgage", params);
// 更新备注
export const updateMarkMortgageCarApi = (params) => http.post("/api/v3/car/mortgage/update_mark", params);
// 获取批量还款的数据
export const get_batch_listApi = (params) => http.post("/api/v3/car/mortgage/get_batch_list", params);

// 营销违章支出列表
export const market_expense_listApi = (params) => http.post("/api/v3/market_expense/list", params);
// 营销违章支出创建
export const market_expense_storeApi = (params) => http.post("/api/v3/market_expense/store", params);
// 营销违章支出更新
export const market_expense_updateApi = (params) => http.post("/api/v3/market_expense/update", params);
// 营销违章支出删除
export const market_expense_deleteApi = (params) => http.post("/api/v3/market_expense/delete", params);

/* 统计相关 */
//首页统计
export const homeStatisticsApi = (params) => http.post("/api/v3/order/home", params, {
    hideLoading: true
});
// 租金统计
export const getUserOrderListApi = (params) => http.post("/api/v3/order/get_user_order_list", params);
// 出勤统计
export const attendanceListApi = (params) => http.post("/api/v3/order/attendance/list", params);
// 出勤统计
export const orderDashboardListApi = (params) => http.post("/api/v3/order/dashboard", params);
// 订单自动分配记录
export const statisticsorder_logApi = (params) => http.post("/api/v3/statistics/change_order_log", params);
// 利息支出
export const statisticsinterestApi = (params) => http.post("/api/v3/statistics/interest", params);
// 保险支出
export const statisticsinsureApi = (params) => http.post("/api/v3/statistics/insure", params);
// 保养支出
export const statisticsmaintenanceApi = (params) => http.post("/api/v3/statistics/maintenance", params);
// 维修支出
export const statisticspay_serviceApi = (params) => http.post("/api/v3/statistics/pay_service", params);
// 残值收入
export const statisticsresidual_valueApi = (params) => http.post("/api/v3/statistics/residual_value", params);
// 事故收入
export const statisticsaccendentApi = (params) => http.post("/api/v3/statistics/accendent", params);
// 租金收入
export const statisticsget_incomeApi = (params) => http.post("/api/v3/statistics/get_income", params);
// 租金收入-月账单
export const statisticsget_income_monthApi = (params) => http.post("/api/v3/statistics/get_income_month", params);
// 刮擦收入
export const statisticsget_scratchApi = (params) => http.post("/api/v3/statistics/get_scratch", params);
// 订单统计图
export const statisticsorder_statisticsApi = (params) => http.post("/api/v3/statistics/order_statistics", params);
// 车辆出租率统计
export const statisticsattendanceApi = (params) => http.post("/api/v3/statistics/attendance", params);
// 营收表
export const statisticsrevenue_statementApi = (params) => http.post("/api/v3/statistics/revenue_statement", params);
// 获取所有车系名称和车牌号名称
export const statisticsselect_nameApi = (params) => http.post("/api/v3/statistics/select_name", params);
// 新增用户统计
export const statisticsuser_add_statisticApi = (params) => http.post("/api/v3/statistics/user_add_statistic", params);

// 数据留存列表
export const retentionListApi = (params) => http.post("/api/v3/retention/index", params);
// 数据留存新增
export const retentionStoreApi = (params) => http.post("/api/v3/retention/store", params);
// 数据留存详情
export const retentionEditApi = (params) => http.post("/api/v3/retention/edit", params);
// 数据留存更新
export const retentionUpdateApi = (params) => http.post("/api/v3/retention/update", params);
// 数据留存删除
export const retentionDeleteApi = (params) => http.post("/api/v3/retention/delete", params);

// 存证列表
export const existingEvidenceListApi = (params) => http.post("/api/v3/existing_evidence/index", params);
// 存证新增
export const existingEvidenceStoreApi = (params) => http.post("/api/v3/existing_evidence/store", params);
// 存证详情
export const existingEvidenceEditApi = (params) => http.post("/api/v3/existing_evidence/edit", params);
// 存证更新
export const existingEvidenceUpdateApi = (params) => http.post("/api/v3/existing_evidence/update", params);
// 存证删除
export const existingEvidenceDeleteApi = (params) => http.post("/api/v3/existing_evidence/delete", params);

// 二手车列表
export const carUsedListApi = (params) => http.post("/api/v3/car_used/index", params);
// 二手车新增
export const carUsedStoreApi = (params) => http.post("/api/v3/car_used/store", params);
// 二手车详情
export const carUsedEditApi = (params) => http.post("/api/v3/car_used/edit", params);
// 二手车更新
export const carUsedUpdateApi = (params) => http.post("/api/v3/car_used/update", params);
// 二手车删除
export const carUsedDeleteApi = (params) => http.post("/api/v3/car_used/delete", params);

// 报销列表
export const reimburseListApi = (params) => http.post("/api/v3/reimburse/index", params);
// 报销新增
export const reimburseStoreApi = (params) => http.post("/api/v3/reimburse/store", params);
// 月结报销新增
export const reimburseStoreMonthApi = (params) => http.post("/api/v3/reimburse/store_month", params);
// 报销详情
export const reimburseEditApi = (params) => http.post("/api/v3/reimburse/edit", params);
// 报销更新
export const reimburseUpdateApi = (params, hideLoading = false) => http.post("/api/v3/reimburse/update", params, {hideLoading});
// 月结报销更新
export const reimburseUpdateMonthApi = (params, hideLoading = false) => http.post("/api/v3/reimburse/update_month", params, {hideLoading});
// 报销删除
export const reimburseDeleteApi = (params) => http.post("/api/v3/reimburse/delete", params);
// 报销审核
export const reimburseExamineApi = (params, hideLoading = false) => http.post("/api/v3/reimburse/examine", params, {hideLoading});
// 报销汇总
export const reimburseTotalsApi = (params) => http.post("/api/v3/reimburse/totals", params);

// 入账单列表
export const entryListApi = (params) => http.post("/api/v3/entry/index", params);
// 入账单新增
export const entryStoreApi = (params) => http.post("/api/v3/entry/store", params);
// 入账单详情
export const entryEditApi = (params) => http.post("/api/v3/entry/edit", params);
// 入账单更新
export const entryUpdateApi = (params) => http.post("/api/v3/entry/update", params);
// 入账单删除
export const entryDeleteApi = (params) => http.post("/api/v3/entry/delete", params);
// 确认入账
export const entryRzApi = (params) => http.post("/api/v3/entry/confirm_rz", params);

